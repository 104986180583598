import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { BASE_URL } from "../Constants";
import ShowOneContentComponent from "./ShowOneContentComponent";
import FooterComponent from "./FooterComponent";

// Utility function to parse query parameters
const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export function ShowOneContentService() {
  const { id } = useParams(); // Only destructure id from useParams
  const queryParams = useQueryParams(); // Get query parameters
  const sid = queryParams.get("sid"); // Get sid from query parameters

  const { data, isLoading, isError } = useQuery(
    ["content", id],
    async () => {
      const response = await axios.get(BASE_URL + `/api/content/v3/${id}`, {
        params: { sid }, // Include sid as a query parameter if needed
      });
      return response.data;
    },
    {
      staleTime: 60000, // Set the stale time to 60 seconds (adjust as needed)
      onSuccess: (data) => {
        let title = data.titlePrimary.split(".").pop();
        data.titleSecondary
          ? (document.title =
              title +
              " | " +
              data.titleSecondary +
              " Lyrics | Gangadwar.in | Gangadwar Bhajnawali")
          : (document.title =
              title + " Lyrics | Gangadwar.in | Gangadwar Bhajnawali");

        axios
          .post(BASE_URL + `/api/content/v3/analytics/${id}`)
          .catch((error) => {
            console.error("Analytics API Error:", error);
          });
      },
    }
  );

  return (
    <>
      {isLoading ? (
        <h4 className="loading">Loading...</h4>
      ) : isError ? (
        <p>Error fetching data</p>
      ) : (
        <>
          <ShowOneContentComponent data={data} />
          <FooterComponent />
        </>
      )}
    </>
  );
}
